var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-small mb-4 pt-3",class:_vm.classTitleCompany,staticStyle:{"min-height":"27vw"}},[_c('b-card-header',{staticClass:"border-bottom text-center"},[_c('h6',{staticClass:"text-left"},[_vm._v("Company information")])]),_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateCompany)}}},[_c('b-form-row',[_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"name_company"}},[_vm._v("Company Name")]),_c('ValidationProvider',{attrs:{"name":"name company","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"name_company","placeholder":"Name","type":"text"},model:{value:(_vm.company.nameCompany),callback:function ($$v) {_vm.$set(_vm.company, "nameCompany", $$v)},expression:"company.nameCompany"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"legal_representative"}},[_vm._v("Legal Representative")]),_c('ValidationProvider',{attrs:{"name":"legal representative","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"legal_representative","placeholder":"Legal representative","type":"text"},model:{value:(_vm.company.legalRepresentative),callback:function ($$v) {_vm.$set(_vm.company, "legalRepresentative", $$v)},expression:"company.legalRepresentative"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"number_company"}},[_vm._v("Company Registration NR.")]),_c('ValidationProvider',{attrs:{"name":"number_company","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"number_company","placeholder":"Company Registration NR.","type":"text"},model:{value:(_vm.company.numberCompany),callback:function ($$v) {_vm.$set(_vm.company, "numberCompany", $$v)},expression:"company.numberCompany"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"email_company"}},[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"email_company","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"email_company","placeholder":"Email","type":"email"},model:{value:(_vm.company.emailCompany),callback:function ($$v) {_vm.$set(_vm.company, "emailCompany", $$v)},expression:"company.emailCompany"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"phone_company"}},[_vm._v("Phone Number")]),_c('ValidationProvider',{attrs:{"name":"phone_company","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"phone_company","placeholder":"Phone Number","type":"text"},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"address_company"}},[_vm._v("Address")]),_c('ValidationProvider',{attrs:{"name":"address_company","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"address_company","placeholder":"Address","type":"text"},model:{value:(_vm.company.address),callback:function ($$v) {_vm.$set(_vm.company, "address", $$v)},expression:"company.address"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',{staticClass:"form-group",attrs:{"md":"12"}},[_c('label',{attrs:{"for":"address_company2"}},[_vm._v("Address 2")]),_c('ValidationProvider',{attrs:{"name":"address_company2","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"address_company2","placeholder":"Address 2","type":"text"},model:{value:(_vm.company.address2),callback:function ($$v) {_vm.$set(_vm.company, "address2", $$v)},expression:"company.address2"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"city_company"}},[_vm._v("City")]),_c('ValidationProvider',{attrs:{"name":"city_company","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"city_company","placeholder":"City","type":"text"},model:{value:(_vm.company.city),callback:function ($$v) {_vm.$set(_vm.company, "city", $$v)},expression:"company.city"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"country_company"}},[_vm._v("Country")]),_c('ValidationProvider',{attrs:{"name":"country_company","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"country_company","placeholder":"Country","type":"text"},model:{value:(_vm.company.country),callback:function ($$v) {_vm.$set(_vm.company, "country", $$v)},expression:"company.country"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"zip_company"}},[_vm._v("Zip Code")]),_c('ValidationProvider',{attrs:{"name":"zip_company","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"zip_company","placeholder":"Zip Code","type":"text"},model:{value:(_vm.company.zipCode),callback:function ($$v) {_vm.$set(_vm.company, "zipCode", $$v)},expression:"company.zipCode"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"web_company"}},[_vm._v("WebSite")]),_c('ValidationProvider',{attrs:{"name":"web_company","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-input',{attrs:{"id":"web_company","placeholder":"WebSite","type":"text"},model:{value:(_vm.company.website),callback:function ($$v) {_vm.$set(_vm.company, "website", $$v)},expression:"company.website"}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()],1)}}],null,true)})],1)],1),(_vm.newCompany !== null)?_c('b-alert',{attrs:{"variant":"dark","show":""}},[_vm._v(" You have submitted an update request")]):_vm._e(),(_vm.newCompany === null && _vm.showButtonUpdate)?_c('button',{staticClass:"btn btn-primary d-table mx-auto",attrs:{"type":"submit"}},[_vm._v("UPDATE COMPANY INFORMATION ")]):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }