import Vue from 'vue';
import {get, post, put} from '@/services/api';
import {
  companyIndex,
  getProfile,
  updateProfile,
  uploadTerms,
  userUpdatePassword,
} from '../../../services/endpoint.json';
import {VueTelInput} from 'vue-tel-input';
import {serializeToDataForm} from "@/utils";
import UpdateCompany from './UpdateCompany'
import TwoFactorActivator from "@/views/dashboard/profile/TwoFactorActivator";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default Vue.extend({
  name: 'Profile',
  mixins: [show_alert_mixin],
  components: {
    VueTelInput,
    UpdateCompany,
    TwoFactorActivator
  },
  data: () => ({
    oldPassword: '',
    passwordOne: '',
    passwordTwo: '',
    id: '',
    country: '',
    countryId: '',
    email: '',
    lastName: '',
    name: ' ',
    phone: '',
    status: '',
    twoFactor: false,
    updatedAt: null,
    avatar: require('@/assets/images/no-photo.svg'),
    type: null,
    userStatus: '',
    validNumber: false,
    controlViewBrand: false,
    urlFile: '',
    urlTerm: '',
    uploadTerm: false,
    terminos: 0,
    showForm: false,
    requestUpdate: false,
    company: null,
    newDataCompany: null,
    userId: null,
    classTitleCompany: '',
    showButtonUpdateCompany: true,
    showFormUpdatePersonal: false,

  }),
  mounted() {
    this.getUserId().then();
  },
  methods: {
    showUpdatePersonal() {
      this.showFormUpdatePersonal = !this.showFormUpdatePersonal
    },
    async getUserId() {
      this.userId = this.$store.getters.getUserId;
      this.getDataProfile().then();
      this.getDataCompany().then();
      const isCompanyEmpty = this.$route.query.company;
      if (isCompanyEmpty !== undefined) {
        if (isCompanyEmpty === 'empty') {
          this.classTitleCompany = 'heart'
        }
      }
    },
    setCountryAndPhone(value, object) {
      if (value !== '') {
        this.phone = value;
      } else {
        this.prone = null;
      }
      this.validNumber = typeof object.valid === 'boolean' && object.valid;
      if (object.country !== undefined) {
        this.country = object.country.name;
      }
    },
    async getDataProfile() {
      let {data} = await get(getProfile, this.userId, true);
      if (Number(data.statusCode) === Number(200)) {
        data = data.message;
        this.uploadTerm = (data.urlTerm != null && data.urlTerm !== '');
        this.type = data.type[0]
        this.name = data.name;
        this.terminos = data.term;
        this.urlTerm = data.urlTerm;
        this.id = data.id;
        this.lastName = data.lastName;
        this.country = data.country;
        this.countryId = data.countryId;
        this.email = data.email;
        this.phone = data.phone;
        this.userStatus = data.status;
        this.updatedAt = data.updatedAt;
        this.requestUpdate = Boolean(data.requestUpdated);
        this.status = (data.status === 1) ? 'ACTIVE' : 'INACTIVE';
        this.twoFactor = (data.twoFactor === 1);
      }
    },
    async getDataCompany() {
      const {data} = await get(companyIndex, this.userId, true)
      if (data.message.company !== null) {
        this.company = data.message.company
        this.showButtonUpdateCompany = false
      }
      if (data.message.companyUpdate !== null) {
        this.newDataCompany = data.message.companyUpdate
      }
    },
    async processFile(e) {
      const type = localStorage.getItem('admin-type')
      const obj = {
        id: this.userId,
        file: e.target.files[0],
        type: type
      }
      const postData = serializeToDataForm(obj)
      await post(uploadTerms, postData, true)
      this.showAlertNotification('File Uploaded')
      this.getDataProfile().then();
    },
    async update() {
      if (this.name !== '' && this.lastName !== '' && this.phone !== '' && this.email !== '') {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          icon: 'question',
          title: 'Are you sure?',
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async (value) => {
          if (value.isConfirmed) {
            const {data} = await put(updateProfile + this.id, {
              name: this.name,
              lastName: this.lastName,
              email: this.email.toLowerCase(),
              phone: this.phone,
              twoFactor: (this.twoFactor === true) ? 1 : 0,
            }, true);
            if (data.statusCode === 200) {
              this.showAlertNotification('<span>Information updated correctly. <br> Please login again.</span>')
              this.showFormUpdatePersonal = false
            } else if (data.statusCode === 406) {
              this.showAlertNotification(`Email ${this.toLowerCase()}  is already registered`, 'error');
            }
          }
        })
      } else {
        this.showAlertNotification('<span>All information is required</span>', 'error')
      }
    },
    validate() {
      return this.name != null && this.lastName != null && this.country != null &&
        this.email != null && this.phone != null && this.status != null && this.twoFactor != null;
    },
    validateObject(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === '') {
          return false;
        }
      }
      return true;
    },
    validateFormPassword() {
      return this.passwordOne !== '' && this.passwordTwo !== '' && this.passwordOne === this.passwordTwo;
    },
    async updateFormpassword() {
      if (this.validateFormPassword()) {
        await post(userUpdatePassword, {
          password: this.passwordOne,
        }, true);
        await this.$swal({
          icon: 'success',
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "OK",
          cancelButtonText: "No",
          html: 'Password updated correctly, please login again',
          showConfirmButton: true,
          onClose: () => {
            post('auth/invalidate-tokens', {id: this.$store.getters.getUserId})
              .then()
              .catch();
          }
        })
        this.$refs.passUpdate.reset();

      } else {
        this.showAlertNotification('Password are different', 'error')
      }
    },
    redirectCreditOrder() {
      if (this.$route.query.order !== undefined) {
        const idOrder = this.$route.query.order;
        this.$router.push({name: 'credit-order-detail', params: {id: idOrder}})
      }
    },
    switchTwoFactor(e) {
      if (e) {
        this.$refs.twoFactor.open(this.userId)
      } else {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          icon: 'question',
          title: 'Are you sure to disable 2FA?',
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((value) => {
          if (value.isConfirmed) {
            post('user/disable-two-factor', {id: this.userId}, true)
              .then(() => {
                this.showAlertNotification('2FA disabled correctly')
              })
          } else {
            this.twoFactor = true
          }
        })
      }
    }
  },
});
