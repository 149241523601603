<template>
  <b-card class="card-small mb-4 pt-3" :class="classTitleCompany" style="min-height: 27vw;">
    <b-card-header class="border-bottom text-center">
      <h6 class="text-left">Company information</h6>
    </b-card-header>
    <b-list-group flush>
      <b-list-group-item>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(updateCompany)">
            <b-form-row>
              <b-col class="form-group" md="6">
                <label for="name_company">Company Name</label>
                <ValidationProvider name="name company" rules="required|min:3">
                  <div slot-scope="{ errors }">
                    <b-input id="name_company" v-model="company.nameCompany" placeholder="Name" type="text"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>

              <b-col class="form-group" md="6">
                <label for="legal_representative">Legal Representative</label>
                <ValidationProvider name="legal representative" rules="required|min:3">
                  <div slot-scope="{ errors }">
                    <b-input id="legal_representative" v-model="company.legalRepresentative"
                             placeholder="Legal representative" type="text"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col class="form-group" md="6">
                <label for="number_company">Company Registration NR.</label>
                <ValidationProvider name="number_company" rules="required|min:3">
                  <div slot-scope="{ errors }">
                    <b-input id="number_company" v-model="company.numberCompany"
                             placeholder="Company Registration NR."
                             type="text"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>
              <b-col class="form-group" md="6">
                <label for="email_company">Email</label>
                <ValidationProvider name="email_company" rules="required|email">
                  <div slot-scope="{ errors }">
                    <b-input id="email_company" v-model="company.emailCompany" placeholder="Email" type="email"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col class="form-group" md="6">
                <label for="phone_company">Phone Number</label>
                <ValidationProvider name="phone_company" rules="required|min:3">
                  <div slot-scope="{ errors }">
                    <b-input id="phone_company" v-model="company.phone" placeholder="Phone Number" type="text"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>
              <b-col class="form-group" md="6">
                <label for="address_company">Address</label>
                <ValidationProvider name="address_company" rules="required|min:3">
                  <div slot-scope="{ errors }">
                    <b-input id="address_company" v-model="company.address" placeholder="Address" type="text"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col class="form-group" md="12">
                <label for="address_company2">Address 2</label>
                <ValidationProvider name="address_company2" rules="required|min:3">
                  <div slot-scope="{ errors }">
                    <b-input id="address_company2" v-model="company.address2" placeholder="Address 2" type="text"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col class="form-group" md="6">
                <label for="city_company">City</label>
                <ValidationProvider name="city_company" rules="required|min:3">
                  <div slot-scope="{ errors }">
                    <b-input id="city_company" v-model="company.city" placeholder="City" type="text"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>
              <b-col class="form-group" md="6">
                <label for="country_company">Country</label>
                <ValidationProvider name="country_company" rules="required|min:3">
                  <div slot-scope="{ errors }">
                    <b-input id="country_company" v-model="company.country" placeholder="Country" type="text"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col class="form-group" md="6">
                <label for="zip_company">Zip Code</label>
                <ValidationProvider name="zip_company" rules="required|min:3">
                  <div slot-scope="{ errors }">
                    <b-input id="zip_company" v-model="company.zipCode" placeholder="Zip Code" type="text"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>
              <b-col class="form-group" md="6">
                <label for="web_company">WebSite</label>
                <ValidationProvider name="web_company" rules="required|min:3">
                  <div slot-scope="{ errors }">
                    <b-input id="web_company" v-model="company.website" placeholder="WebSite" type="text"/>
                    <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
                  </div>
                </ValidationProvider>
              </b-col>
            </b-form-row>
            <b-alert v-if="newCompany !== null" variant="dark" show> You have submitted an update request</b-alert>
            <button v-if="newCompany === null && showButtonUpdate" class="btn btn-primary d-table mx-auto"
                    type="submit">UPDATE COMPANY INFORMATION
            </button>
          </form>
        </ValidationObserver>
      </b-list-group-item>
    </b-list-group>

  </b-card>
</template>

<script>
import {post} from "@/services/api";
import {userCreateCompany, userRequestUpdateProfile} from "@/services/endpoint.json";
import {decrypt} from "@/helpers/encrypt";
import show_alert_mixin from "@/utils/show_alert_mixin";

const fieldCompany = {
  userId: '',
  nameCompany: '',
  legalRepresentative: '',
  numberCompany: '',
  emailCompany: '',
  phone: '',
  address: '',
  address2: '',
  city: '',
  country: '',
  zipCode: '',
  website: '',
  login: false,
  admin: false
}
export default {
  name: "UpdateCompany",
  mixins: [show_alert_mixin],
  props: {
    infoCompany: {
      default: fieldCompany,
    },
    infoNewCompany: {},
    infoClassTitleCompany: '',
    infoShowButtonUpdate: {
      default: true
    }
  },

  computed: {
    company() {
      if (this.infoCompany !== null) {
        return this.infoCompany
      } else {
        return fieldCompany
      }

    },
    newCompany() {
      return this.infoNewCompany
    },
    classTitleCompany() {
      return this.infoClassTitleCompany
    },
    showButtonUpdate() {
      return this.infoShowButtonUpdate
    }
  },
  methods: {
    async updateCompany() {
      this.company.id = this.id;
      this.company.other = false;
      const store = localStorage.getItem('admin-id')
      this.company.userId = decrypt(store)
      this.company.role = localStorage.getItem('admin-type')
      this.company.login = true;
      const tempRole = decrypt(this.company.role)
      this.company.admin = (tempRole == 'administrator')
      const {data} = await post(userCreateCompany, this.company, true);
      const isCompanyEmpty = this.$route.query.company;
      const isLogin = this.$route.query.login;
      let msg = "Process done correctly. The information is being validated.";
      if (this.newCompany !== null) {
        msg = "You had a previous request. The request was updated and is being validated"
      }
      if (this.$userCan('administrator')) {
        msg = 'Process done correctly'
      }
      if (isCompanyEmpty !== undefined && isLogin !== undefined) {
        msg = 'Process done correctly. The information is been validated.'
      }
      if (Number(data.statusCode) === Number(200)) {
        this.showAlertNotification(msg)
        window.location.reload()
      } else {
        this.showAlertNotification("Error")
      }
    },
    async logout() {
      this.$socket.close();
      try {
        localStorage.clear();
        window.localStorage.clear();
        this.$store.commit('setUser', null);
        this.$router.push('/login')
          .catch();
      } catch (e) {
        await this.logout();
      }
    },
  }
}
</script>

<style scoped>
.heart {
  width: 100%;
  border: 2px;
  color: #3D5170;
  animation: beat 1s infinite alternate;
}

/* Heart beat animation */
@keyframes beat {
  to {
    box-shadow: 0 0 10px #d14343;
  }
}
</style>
