<template>
  <b-modal ref="modal" title="Two-Factor Authentication" body-class="pb-0" no-close-on-backdrop @close="cancel">
    <div class="d-flex">
      <div class="pr-2">
        <h6>Step 1: Configure your 2FA app</h6>
        <p>
          To enable 2FA, you will need to have a 2FA auth app
          installed on your phone or tablet (example include Google Authenticator,
          Duo Mobile, and Authy),
        </p>
        <p>
          Most apps will let you get set up by scanning our QR code
          from within the app. if you prefer, you can type in the key manually.
        </p>
      </div>
      <div ref="qrcode" class="mt-2"></div>
    </div>
    <div>
      <p>
        <i class="material-icons">vpn_key</i> {{result.base32}}
      </p>
      <h6 class="mt-2">
        Step 2: Enter a 2FA
      </h6>
      <p>
        Generate a code from your newly-activated 2FA app to confirm that you are set up.
      </p>
    </div>
    <div class="form-group">
      <label class="font-weight-bold">To confirm the secret, enter the 6-digit code from the app.</label>
      <b-form-input v-model="token" @keyup.enter="verifyToken"/>
      <div class="text-right mt-2">
        <b-button variant="primary" @click="verifyToken">SUBMIT</b-button>
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="danger" @click="cancel()">CANCEL</b-button>
    </template>
  </b-modal>
</template>

<script>
import {post} from "@/services/api";
import * as QRCode from 'easyqrcodejs';

export default {
  name: "TwoFactorActivator",
  data: () => ({
    token: '',
    userId: null,
    result: {}
  }),
  methods: {
    open(userId) {
      this.userId = userId
      this.$refs.modal.show()
      post('user/enable-two-factor', {id: userId}, true).then(({data}) => {
        this.createQr(data.otpauth_url)
        this.result = data
      })
    },
    createQr(url) {
      new QRCode(this.$refs.qrcode, {
        text: url,
        logoBackgroundTransparent: true,
        width: 200,
        height: 200,
      });
    },
    async verifyToken() {
      post('user/verify-two-factor', {id: this.userId, token: this.token}, true)
        .then(async ({data}) => {
        if (data){
          await this.$swal({
            title: 'Two-Factor Authentication Is On',
            text: 'We will ask for a verification code to login.',
            timer: 6000,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d14343',
          })
          this.$emit('result', true);
          this.$refs.modal.hide()
        } else {
          await this.$swal({
            title: 'Invalid code !',
            icon: 'error',
            timer: 2500,
            text: 'Please try again.',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d14343',
          })
        }
      })
    },
    cancel(){
      this.$refs.modal.hide()
      this.$emit('result', false);
    }
  }
}
</script>

<style scoped>
p{
  margin-bottom: .8em;
}
</style>
